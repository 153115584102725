// GLobal imports (js)
import "spectrum-colorpicker/spectrum.css";
import "spectrum-colorpicker/spectrum.js";
import vSelect from "vue-select";
import VueTimepicker from "vue3-timepicker";
import draggable from "vuedraggable";
import { DatePicker } from "v-calendar";
// import ToastPlugin from "vue-toast-notification";
import PortalVue from "portal-vue";
import Toast from "vue-toastification";
import VueTippy from "vue-tippy";
import axios from "axios";
import VueAxios from "vue-axios";
import VueFeather from "vue-feather";
import { createProPlugin, inputs } from "@formkit/pro";
import { plugin, defaultConfig } from "@formkit/vue";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

// Global imports (styles)
import "vue3-timepicker/dist/VueTimepicker.css";
import "v-calendar/style.css";
import "vue-toastification/dist/index.css";

// Local imports
import "./Translation";
import { ConfirmDialog } from "./plugins/ConfirmDialog";
import InitNativeBootstrapPlugins from "../backoffice/utils/native_bootstrap/_init";
import CitySelector from "./components/CitySelector.vue";
import GateEntriesFilter from "../backoffice/modules/access_control/GateEntriesFilter.vue";
import PriceList from "../backoffice/modules/booking/prices/PriceList.vue";
import ProductUnitForm from "../backoffice/modules/cafeteria/stock/ProductUnitForm.vue";
import StockRegistrationForm from "../backoffice/modules/cafeteria/stock_registration/Form.vue";
import StornoForm from "../backoffice/modules/cafeteria/stock_registration/StornoForm.vue";
import EditClubData from "../backoffice/modules/clubs/customers/EditClubData.vue";
import CrudFilter from "../backoffice/modules/crud/filter/CrudFilter.vue";
import CashRegisterBankNotes from "../backoffice/modules/sales/CashRegisterBankNotes.vue";
import SellProductForm from "../backoffice/modules/sales/SellProductForm.vue";
import MessageAttachments from "../backoffice/modules/support/MessageAttachments.vue";
import CustomerList from "../backoffice/components/CustomerList.vue";
import DaysOfWeekPicker from "../backoffice/components/DaysOfWeekPicker.vue";
import Evaporate from "../backoffice/components/Evaporate.vue";
import GoToAnywhere from "../backoffice/components/GoToAnywhere.vue";
import HeaderSearch from "../backoffice/components/HeaderSearch.vue";
import Modal from "../backoffice/components/Modal.vue";
import Paginator from "../backoffice/components/Paginator.vue";
import SearchableSelect from "../backoffice/components/Select.vue";
import TagInput from "../backoffice/components/TagInput.vue";
import TimePicker from "../backoffice/components/TimePicker.vue";
import UserPicker from "../backoffice/components/UserPicker.vue";
import WebCam from "../backoffice/components/WebCam.vue";
import Checkbox from "../backoffice/components/inputs/Checkbox.vue";
import GetApiToken from "../backoffice/components/Integrations/GetApiToken.vue";
import ListApiTokens from "../backoffice/components/Integrations/ListApiTokens.vue";
import SmLandingModal from "../backoffice/components/SmLandingModal.vue";
import BookingSettings from "../backoffice/pages/BookingSettings.vue";
import BusinessPlanSelector from "../backoffice/pages/BusinessPlanSelector.vue";
import MassTransfer from "../backoffice/pages/MassTransfer.vue";
import Permissions from "../backoffice/pages/Permissions.vue";
import OnboardingModal from "../backoffice/pages/Onboarding/OnboardingModal.vue";
import CashRegisterTrafficCountHourly from "../backoffice/reports/CashRegisterTrafficCountHourly.vue";
import Autofocus from "../backoffice/directives/Autofocus.vue";
import BootstrapDatepicker from "../backoffice/directives/BootstrapDatepicker.vue";
import CleaveDirective from "../backoffice/directives/Cleave.vue";
import Numeric from "../backoffice/directives/Numeric.vue";
import CheckinManualCounter from "../backoffice/components/CheckinManualCounter.vue";

const GlobalVue = {
  registerComponents(app) {
    app.component("VueTimepicker", VueTimepicker);
    app.component("VSelect", vSelect);
    app.component("Draggable", draggable);
    app.component("VcDatePicker", DatePicker);
    app.component("SearchableSelect", SearchableSelect);
    app.component("BookingSettingsPage", BookingSettings);
    app.component("HeaderSearch", HeaderSearch);
    app.component("GoToAnywhere", GoToAnywhere);
    app.component("StockRegistrationForm", StockRegistrationForm);
    app.component("StockRegistrationStornoForm", StornoForm);
    app.component("SellProductForm", SellProductForm);
    app.component("ProductUnitForm", ProductUnitForm);
    app.component("CashRegisterBankNotes", CashRegisterBankNotes);
    app.component("CrudFilter", CrudFilter);
    app.component("CustomerList", CustomerList);
    app.component("VuePaginator", Paginator);
    app.component("UserPicker", UserPicker);
    app.component("MessageAttachments", MessageAttachments);
    app.component("BusinessPlanSelector", BusinessPlanSelector);
    app.component("PermissionsPage", Permissions);
    app.component("MassTransferPage", MassTransfer);
    app.component("EvaporateInput", Evaporate);
    app.component("TagInput", TagInput);
    app.component("CashRegisterTrafficCountHourly", CashRegisterTrafficCountHourly);
    app.component("WebCam", WebCam);
    app.component("EditClubData", EditClubData);
    app.component("PriceList", PriceList);
    app.component("DaysOfWeekPicker", DaysOfWeekPicker);
    app.component("Modal", Modal);
    app.component("CitySelector", CitySelector);
    app.component("TimePicker", TimePicker);
    app.component("GateEntriesFilter", GateEntriesFilter);
    app.component("AsyncCheckbox", Checkbox);
    app.component("GetApiToken", GetApiToken);
    app.component("ListApiTokens", ListApiTokens);
    app.component("SmLandingModal", SmLandingModal);
    app.component("OnboardingModal", OnboardingModal);
    app.component("CheckinManualCounter", CheckinManualCounter);
    app.component(VueFeather.name, VueFeather);
    draggable.compatConfig = { MODE: 3 };
    VueFeather.compatConfig = { RENDER_FUNCTION: false };
  },
  registerComposables(app) {
    const pro = createProPlugin("fk-8784f00eca7", inputs);
    app.use(plugin, defaultConfig({ plugins: [pro] }));
    app.use(PortalVue);
    app.use(ConfirmDialog);
    app.use(InitNativeBootstrapPlugins);
    app.use(VueAxios, axios);
    app.use(autoAnimatePlugin);
    app.use(Toast, {
      icon: false,
      pauseOnHover: false,
      hideProgressBar: true,
      showCloseButtonOnHover: true,
      timeout: 3000
    });
    app.use(VueTippy, {
      directive: "tippy",
      component: "tippy",
    });
    app.provide("axios", app.config.globalProperties.axios);
  },
  registerDirectives(app) {
    app.directive("focus", Autofocus);
    app.directive("datepicker", BootstrapDatepicker);
    app.directive("cleave", CleaveDirective);
    app.directive("numeric-input", Numeric);
  }
};

export default GlobalVue;
