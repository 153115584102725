<template>
  <settings-layout>
    <in-head :title="$t('integrations.settings.title')" />

    <h4 class="mb-4">
      {{ $t('integrations.tokens.parking') }}
    </h4>
    <sm-card>
      <get-api-token
        :route="page.props.parkingTokenRoute"
        class="mb-4" />
      <list-api-tokens
        :route="page.props.tokenListRoute"
        ability="parking" />
    </sm-card>

    <h4 class="mb-4">
      {{ $t('integrations.tokens.solarium') }}
    </h4>
    <sm-card>
      <get-api-token
        :route="page.props.solariumTokenRoute"
        class="mb-4" />
      <list-api-tokens
        :route="page.props.tokenListRoute"
        ability="solarium" />
    </sm-card>

    <h4 class="mb-4">
      {{ $t('integrations.tokens.kiosk') }}
    </h4>
    <sm-card>
      <get-api-token
        :route="page.props.kioskTokenRoute"
        class="mb-4" />
      <list-api-tokens
        :route="page.props.tokenListRoute"
        ability="kiosk" />
    </sm-card>

    <h4 class="mb-4">
      {{ $t('integrations.tokens.lockers') }}
    </h4>
    <sm-card>
      <get-api-token
        :route="page.props.lockerControllerTokenRoute"
        class="mb-4" />
      <list-api-tokens
        :route="page.props.tokenListRoute"
        ability="lockers:identify" />
    </sm-card>

    <h4 class="mb-4">
      {{ $t('integrations.tokens.gates') }}
    </h4>
    <sm-card>
      <get-api-token
        :route="page.props.gateAccessTokenRoute"
        class="mb-4" />
      <list-api-tokens
        :route="page.props.tokenListRoute"
        ability="gate:access" />
    </sm-card>
  </settings-layout>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
// default variables
const page = usePage();

defineProps({
  tokenListRoute: { type: String, required: true, default: "" },
  solariumTokenRoute: { type: String, required: true, default: "" },
  kioskTokenRoute: { type: String, required: true, default: "" },
  parkingTokenRoute: { type: String, required: true, default: "" },
  lockerControllerTokenRoute: { type: String, required: true, default: "" },
});
</script>
