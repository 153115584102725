<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <vc-date-picker
      :id="id"
      ref="datePickerRef"
      v-model="dateModelValue"
      name="appointmentDate"
      locale="hu"
      :min-date="minDate"
      :max-date="maxDate"
      :masks="{ data: 'YYYY. MM. DD.', input: 'YYYY. MM. DD.'}"
      mode="date"
      :required="required"
      :popover="popover"
      :input-debounce="300"
      @popover-did-show="popoverDidShow"
      @update:modelValue="updateInput($event)"
    >
      <template #default="{ inputValue, inputEvents, hidePopover }">
        <input
          :id="inputId"
          ref="datePickerInputRef"
          type="text"
          class="sm-input-text"
          :value="inputValue"
          :required="required"
          :disabled="disabled"
          :placeholder="placeholder"
          v-on="inputEvents"
          @keypress.enter.prevent="onEnter(hidePopover)"
        >
      </template>
    </vc-date-picker>
    <template #error>
      <div v-auto-animate>
        <div
          v-if="localError !== ''"
          class="sm-feedback error">
          {{ localError }}
        </div>
      </div>
    </template>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import { useDateFormat } from "@/inertia/composables/global";
import { format, formatISO, isValid } from "date-fns";

const popover = ref({
  visibility: "focus",
});

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  inputId: { type: String, required: false, default: "" },
  modelValue: { type: [Date, String], required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: `pl: ${useDateFormat(new Date())}` },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  inlineInputWidth: { type: String, required: false, default: "" },
  inputRef: { type: String, required: false, default: "" },
  focusToday: { type: Boolean, required: false, default: false },
  minDate: { type: [Date, String], required: false, default: null },
  maxDate: { type: [Date, String], required: false, default: null },
});

const datePickerRef = ref();
const localError = ref("");

provide("props", props);

const dateModelValue = ref(props.modelValue ? formatISO(new Date(props.modelValue)) : "");

watch(() => props.modelValue, (e) => {
  dateModelValue.value = e && e !== "" ? formatISO(new Date(e)) : "";
});

const emit = defineEmits(["update:modelValue", "change", "onEnter"]);

function updateInput(e) {
  const formattedDate = e ? format(e, "yyyy. MM. dd.") : "";
  emit("update:modelValue", formattedDate);
  emit("change", formattedDate);
}

function popoverDidShow() {
  if (props.focusToday) {
    datePickerRef.value.calendarRef.focusDate(new Date());
  }
}

const datePickerInputRef = ref();

function onEnter(hidePopover) {
  console.log("onEnter?");
  hidePopover();
  const newValue = new Date(datePickerInputRef.value.value);

  if (!isValid(newValue) || newValue.getFullYear() < 1900) {
    localError.value = window.trans("errors.form.datepicker.valid_date", { date_with_dot: format(new Date(), "yyyy. MM. dd."), date_with_dash: format(new Date(), "yyyy-MM-dd") });
  } else {
    localError.value = "";
    dateModelValue.value = newValue;
  }
  emit("onEnter");
}

</script>
