<template>
  <div>
    <sm-card>
      <template #header>
        <p>Picker inputs</p>
      </template>

      <p class="mb-4">
        <strong>Time picker</strong>
      </p>
      <div class="grid grid-cols-5 gap-4 mb-4">
        <div class="space-y-2">
          <sm-time-picker
            v-model="timePicker"
            label="Time picker" />
        </div>
        <div class="space-y-2">
          <sm-time-picker
            v-model="emtpyPicker"
            label="Time picker with placeholder"
            placeholder="Placeholder text" />
        </div>
        <div class="space-y-2">
          <sm-time-picker
            v-model="emtpyPicker"
            label="Time picker with help text"
            help="Here, let me help for you with this" />
        </div>
        <div class="space-y-2">
          <sm-time-picker
            v-model="emtpyPicker"
            label="Time picker with error"
            error="Oh-oh, something is wrong with this input" />
        </div>
        <div class="space-y-2">
          <sm-time-picker
            v-model="emtpyPicker"
            label="Time picker disabled"
            disabled />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mb-4">
        <div class="space-y-2">
          <sm-time-picker
            v-model="emtpyPicker"
            label="Time picker inline"
            inline />
        </div>
      </div>

      <p class="mb-4">
        <strong>Date picker</strong>
      </p>
      <div class="grid grid-cols-5 gap-4 mb-4">
        <div class="space-y-2">
          <sm-date-picker
            v-model="datePicker"
            label="Date picker" />
        </div>
        <div class="space-y-2">
          <sm-date-picker
            v-model="emtpyPicker"
            label="Date picker with placeholder"
            placeholder="Placeholder text" />
        </div>
        <div class="space-y-2">
          <sm-date-picker
            v-model="emtpyPicker"
            label="Date picker with help text"
            help="Here, let me help for you with this" />
        </div>
        <div class="space-y-2">
          <sm-date-picker
            v-model="emtpyPicker"
            label="Date picker with error"
            error="Oh-oh, something is wrong with this input" />
        </div>
        <div class="space-y-2">
          <sm-date-picker
            v-model="emtpyPicker"
            label="Date picker disabled"
            disabled />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mb-4">
        <div class="space-y-2">
          <sm-date-picker
            v-model="emtpyPicker"
            label="Date picker inline"
            inline />
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const emtpyPicker = ref("");
const timePicker = ref("11:00");
const datePicker = ref("2023-09-01");
</script>
