<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add title to the button<br><br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> title<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">title="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-button
              title="Button name" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add hotkey string between brackets after the title<br><br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> hotkey<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">hotkey="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-button
              title="Button name"
              hotkey="F12" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the color of the button<br><br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> color<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Available values:</strong> primary, primary-outline, success, success-outline, info, info-outline, danger, danger-outline<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">color="..."</pre></code>
            </p>
          </div>
          <div class="flex flex-wrap w-1/2 sm-col">
            <div class="mb-2 mr-2">
              <sm-button
                title="Default (white) button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="primary"
                title="Primary button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="primary-outline"
                title="Primary outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="success"
                title="Success button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="success-outline"
                title="Success outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="info"
                title="Info button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="info-outline"
                title="Info outline button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="danger"
                title="Danger button" />
            </div>
            <div class="mb-2 mr-2">
              <sm-button
                color="danger-outline"
                title="Danger outline button" />
            </div>
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-4">
              Set the size of the button<br><br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> size<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> xs<br>
              <strong class="inline-block pl-2 w-36">Available values:</strong> xs, base, lg, input, icon<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">size="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <div class="mb-2">
              <sm-button title="Default size button (32px)" />
            </div>
            <div class="mb-2">
              <sm-button
                size="base"
                title="Base size button (38px)" />
            </div>
            <div class="mb-2">
              <sm-button
                size="lg"
                title="Lg size button (40px)" />
            </div>
            <div class="mb-2">
              <sm-button
                size="input"
                title="Input size button (38px)" />
            </div>
            <div class="mb-2">
              <sm-button
                size="icon"
                icon="check" />
            </div>
          </div>
        </div>
        <hr>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Add feather icon before / after to the button<br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> icon<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">icon="..."</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <sm-button
                icon="check"
                title="Button with icon" />
            </div>
          </div>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Set the position pf the the icon <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> icon-position<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
                <strong class="inline-block pl-2 w-36">Available values:</strong> left, right<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">icon="..." icon-position="..."</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <div class="mb-2">
                <sm-button
                  icon="check"
                  icon-position="left"
                  title="Button with icon on the left" />
              </div>
              <div class="mb-2">
                <sm-button
                  icon="check"
                  icon-position="right"
                  title="Button with icon on the right" />
              </div>
            </div>
          </div>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Add loading indicator (with disabled state) to the button <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> loading<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">loading="true"</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <sm-button
                title="Loader button (click to toggle)"
                :loading="loading"
                @click="toggleLoading" />
            </div>
          </div>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Add disabled state to the button <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> disabled<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">disabled="true"</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <sm-button
                disabled
                title="Button with disabled state" />
            </div>
          </div>
          <hr>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Change the type of the html button <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> type<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">type="..."</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <sm-button
                type="submit"
                title="This is a submit button" />
            </div>
          </div>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Create a simple link (&lt;a /&gt; tag) with button style <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> href<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">href="..."</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <sm-button
                href="#"
                title="Simple link" />
            </div>
          </div>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Generate link as any html element <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> as<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">as="..."</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <sm-button
                href="#"
                as="span"
                title="Button link generated as a span" />
            </div>
          </div>
          <hr>
          <hr>
        </div>

        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Define the default style and text of the button as a preset <br><br>
                <strong class="inline-block pl-2 w-36">Prop name:</strong> preset<br>
                <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
                <strong class="inline-block pl-2 w-36">Available values:</strong> add, save, delete, delete-icon, edit, edit-icon, back<br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">preset="..."</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <div class="mb-2">
                <sm-button preset="add" />
              </div>
              <div class="mb-2">
                <sm-button preset="save" />
              </div>
              <div class="flex mb-2">
                <sm-button
                  preset="delete"
                  class="mr-2" />
                <sm-button preset="delete-icon" />
              </div>
              <div class="flex mb-2">
                <sm-button
                  preset="edit"
                  class="mr-2" />
                <sm-button preset="edit-icon" />
              </div>
              <div class="mb-2">
                <sm-button preset="back" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </sm-card>

    <sm-card>
      <div>
        <div class="">
          <div class="sm-row">
            <div class="w-1/2 sm-col">
              <p class="mb-2">
                Create a toggleable button with options <br><br>
                <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">&lt;sm-toggle-button :options="toggleButtonOptions" /&gt;</pre></code>
              </p>
            </div>
            <div class="w-1/2 sm-col">
              <div class="text-xs">
                <sm-toggle-button
                  title="Toggle button"
                  :options="toggleButtonOptions" />
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const loading = ref(false);
const toggleLoading = () => {
  loading.value = !loading.value;
  setTimeout(() => {
    loading.value = !loading.value;
  }, 1500);
};
const toggleButtonOptions = ref([
  {
    id: 1,
    label: "First element",
    type: "link",
    to: "/valami",
    icon: "plus-circle"
  },{
    id: 2,
    label: "Second element",
    type: "link",
    to: "/valami",
    icon: "plus-circle"
  },{
    id: 3,
    label: "Third element",
    type: "link",
    to: "/valami",
    icon: "plus-circle"
  },{
    id: 4,
    label: "Fourth element",
    type: "link",
    to: "/valami",
    icon: "plus-circle"
  }
]);
</script>
