<template>
  <ConfirmDialog :group="group">
    <template #container="{ message, onAccept, onReject }">
      <div class="relative w-auto m-2 my-7 mx-auto max-w-[95vw] lg:max-w-lg">
        <div class="relative flex flex-col bg-white border rounded-lg pointer-events-auto border-brand-gray-200 w-84">
          <header
            v-if="message.header"
            class="flex items-start justify-between p-4 border-b border-brand-gray-200 rounded-t-md">
            <h5 class="mb-0 leading-normal">
              {{ message.header }}
            </h5>
          </header>
          <div
            v-if="message.message"
            class="relative p-4">
            <p class="mb-0">
              {{ message.message }}
            </p>
          </div>
          <div
            v-if="$slots.default"
            class="px-4">
            <slot />
          </div>
          <div class="flex flex-wrap items-center justify-end p-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
            <sm-button
              v-if="hasCancelButton"
              :title="cancelLabel"
              color="secondary"
              @click="onReject" />
            <sm-button
              v-if="hasAcceptButton && !hasFakeAcceptButton"
              :title="acceptLabel"
              color="primary"
              @click="onAccept" />
            <sm-button
              v-if="hasFakeAcceptButton"
              :title="fakeAcceptLabel"
              color="primary"
              @click="onFakeAccept" />
          </div>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
defineProps({
  group: { type: String, required: true, default: null },
  hasAcceptButton: { type: Boolean, required: false, default: true },
  hasFakeAcceptButton: { type: Boolean, required: false, default: false },
  hasCancelButton: { type: Boolean, required: false, default: true },
  acceptLabel: { type: String, required: false, default: window.trans("generic.yes") },
  fakeAcceptLabel: { type: String, required: false, default: window.trans("generic.yes") },
  cancelLabel: { type: String, required: false, default: window.trans("generic.cancel") },
});

const emits = defineEmits(["onFakeAccept"]);

const onFakeAccept = () => {
  emits("onFakeAccept");
};
</script>
