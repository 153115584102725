<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the name attribute to the picker<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> name<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">name="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              label="Date picker with name prop"
              name="name" />
            <sm-time-picker
              label="time picker with name prop"
              name="name" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the id attribute to the picker<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> id<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              id="datepicker_id"
              label="Date picker with id prop" />
            <sm-time-picker
              id="timepicker_id"
              label="Time picker with id prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the id attribute to the picker's input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inputId<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">input-id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              input-id="datepicker_input_id"
              label="Date picker with id prop" />
            <sm-time-picker
              input-id="timepicker_input_id"
              label="Time picker with id prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add error text and state to the picker<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> error<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean, String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">error="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              error="Some error text"
              label="Date picker with error prop" />
            <sm-time-picker
              error="Some error text"
              label="Time picker with error prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add disabled state to picker<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> disabled<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:disabled="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              disabled
              label="Date picker with disabled prop" />
            <sm-time-picker
              disabled
              label="Time picker with disabled prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add required state to picker<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> required<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:required="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              required
              label="Date picker with required prop" />
            <sm-time-picker
              required
              label="Time picker with required prop" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the label inline before the picker<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inline<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:inline="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              inline
              label="Date picker with inline prop" />
            <sm-time-picker
              inline
              label="Time picker with inline prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the picker with half width<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> half<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:half="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              half
              label="Date picker with half prop" />
            <sm-time-picker
              half
              label="Time picker with half prop" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Automatically focus today when the datepicker opens (for keyboard navigation)<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> focusToday<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:focus-today="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              focus-today
              label="Date picker with focusToday prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the minimun date for the datepicket<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> minDate<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">min-date="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              :min-date="new Date()"
              label="Date picker with minDate prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the maximum date for the datepicket<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> maxDate<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">max-date="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-date-picker
              :max-date="new Date()"
              label="Date picker with maxDate prop" />
          </div>
        </div>
        <hr>
      </div>
    </sm-card>
  </div>
</template>

