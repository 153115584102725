import * as Sentry from "@sentry/browser";
import * as SentryVue from "@sentry/vue";
import Vue from "vue";

if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN !== "") {
  const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
  const initialSentryScope = {
    user: null
  };

  if (window.app && window.app.user) {
    initialSentryScope.user = { id: window.app.user.id };
  }

  const config = {
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", "smc.sportmate.dev", "app.sportmateclub.com"],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.01,
    tracesSampleRate: 0.01,
    initialScope: initialSentryScope,
    ignoreErrors: ["CanceledError", "CancelledError", "AbortError", "Request aborted"],
  };

  Sentry.init(config);
  SentryVue.init({ ...config, Vue });
}
