<template>
  <ul class="sm-nav-tabs-material">
    <li
      v-for="(navtab, index) in data"
      :key="navtab?.id || index">
      <template v-if="isLinkEnabled(navtab)">
        <Link
          v-if="navtab.inertia"
          :href="navtab.to"
          class="transition-all"
          :class="{'active': navtab.active}">
          {{ navtab.label }}
        </Link>
        <a
          v-else
          :href="navtab.to"
          class="transition-all"
          :class="{'active': navtab.active}">
          {{ navtab.label }}
        </a>
      </template>
    </li>
  </ul>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";

const isLinkEnabled = (navtab) => {
  if (navtab.enabled === false) {
    return false;
  }

  return true;
};

defineProps({
  data: { type: Array, required: true, default: () => [] }
});
</script>
