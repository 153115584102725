<template>
  <div>
    <sm-card>
      <template #header>
        <p>Toggleable input</p>
      </template>

      <p class="mb-4">
        <strong>Checkbox</strong>
      </p>
      <div class="grid grid-cols-4 gap-4 mb-4">
        <div class="space-y-2">
          <sm-checkbox
            label="Simple checkbox with value"
            :value="true"
            text="Checkbox with value"
          />
        </div>
        <div class="space-y-2">
          <sm-checkbox
            v-model="checkboxModel"
            text="Checkbox with model"
            :label="`Simple checkbox with v-model: ${checkboxModel}`" />
        </div>
        <div class="space-y-2">
          <sm-checkbox
            text="Simple disabled checkbox"
            disabled
            label="Simple disabled checkbox" />
        </div>
        <div class="space-y-2">
          <sm-checkbox
            text="Simple switch checkbox"
            sm-switch
            label="Simple switch checkbox" />
        </div>
        <div class="space-y-2">
          <sm-checkbox
            label="Simple checkbox with help text"
            :value="true"
            text="Checkbox with help"
            help="Here, let me help for you with this"
          />
        </div>
        <div class="space-y-2">
          <sm-checkbox
            label="Simple checkbox with error"
            :value="true"
            text="Checkbox with error"
            error="Oh-oh, something is wrong with this input"
          />
        </div>
        <div />
        <div class="space-y-2">
          <sm-checkbox
            text="Simple switch checkbox with error"
            sm-switch
            label="Simple switch checkbox error"
            error="Oh-oh, something is wrong with this input" />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 mb-4">
        <div class="space-y-2">
          <sm-checkbox
            label="Simple checkbox with value"
            inline
            text="Checkbox with value"
          />
        </div>
      </div>

      <p class="mb-4">
        <strong>Radio</strong>
      </p>
      <div class="grid grid-cols-3 gap-4 mb-4">
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioGroupModel"
            name="radio_name_01"
            :label="`This is a radio group: ${radioGroupModel}`"
            :items="radioGroupItems"
          />
        </div>
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioGroupHelpModel"
            name="radio_name_error"
            :label="`This is a radio group: ${radioGroupHelpModel}`"
            help="Here, let me help for you with this"
            :items="radioGroupHelpItems"
          />
        </div>
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioGroupErrorModel"
            name="radio_name_error"
            :label="`This is a radio group: ${radioGroupErrorModel}`"
            error="Oh-oh, something is wrong with this input"
            :items="radioGroupErrorItems"
          />
        </div>
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioGroupDisabledModel"
            name="radio_name_disabled"
            :label="`This is a radio group: ${radioGroupDisabledModel}`"
            disabled
            :items="radioGroupDisabledItems"
          />
        </div>
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioGroupDisabledOneModel"
            name="radio_name_disabled_one"
            :label="`This is a radio group: ${radioGroupDisabledOneModel}`"
            disabled="radio_group_disabled_one_02"
            :items="radioGroupDisabledOneItems"
          />
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4 mb-4">
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioButtonGroupModel"
            name="radio_name_button"
            :label="`This is a radio group: ${radioButtonGroupModel}`"
            button-group
            :items="radioButtonGroupItems"
          />
        </div>
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioButtonGroupDisabledModel"
            name="radio_name_button_disabled"
            :label="`This is a radio group: ${radioButtonGroupDisabledModel}`"
            button-group
            disabled
            :items="radioButtonGroupDisabledItems"
          />
        </div>
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioButtonGroupDisabledOneModel"
            name="radio_name_button_disabled"
            :label="`This is a radio group: ${radioButtonGroupDisabledOneModel}`"
            button-group
            disabled="radio_button_group_disabled_one_02"
            :items="radioButtonGroupDisabledOneItems"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 mb-4">
        <div class="space-y-2">
          <sm-radio-group
            v-model="radioGroupModel"
            name="radio_name_inline"
            label="Inline"
            :items="radioGroupItems"
            inline
          />
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const checkboxModel = ref(true);

const radioGroupModel = ref("radio_group_01");
const radioGroupItems = ref([
  {
    id: "radio_group_01",
    text: "First element"
  },{
    id: "radio_group_02",
    text: "Second element"
  }
]);

const radioGroupHelpModel = ref("radio_group_help_01");
const radioGroupHelpItems = ref([
  {
    id: "radio_group_help_01",
    text: "First element"
  },{
    id: "radio_group_help_02",
    text: "Second element"
  }
]);

const radioGroupErrorModel = ref("radio_group_error_01");
const radioGroupErrorItems = ref([
  {
    id: "radio_group_error_01",
    text: "First element"
  },{
    id: "radio_group_error_02",
    text: "Second element"
  }
]);

const radioGroupDisabledModel = ref("radio_group_disabled_01");
const radioGroupDisabledItems = ref([
  {
    id: "radio_group_disabled_01",
    text: "First element"
  },{
    id: "radio_group_disabled_02",
    text: "Second element"
  }
]);

const radioGroupDisabledOneModel = ref("radio_group_disabled_one_01");
const radioGroupDisabledOneItems = ref([
  {
    id: "radio_group_disabled_one_01",
    text: "First element"
  },{
    id: "radio_group_disabled_one_02",
    text: "Second element"
  },{
    id: "radio_group_disabled_one_03",
    text: "Third element"
  }
]);

const radioButtonGroupModel = ref("radio_button_group_01");
const radioButtonGroupItems = ref([
  {
    id: "radio_button_group_01",
    text: "First element"
  },{
    id: "radio_button_group_02",
    text: "Second element"
  }
]);

const radioButtonGroupDisabledModel = ref("radio_button_group_disabled_01");
const radioButtonGroupDisabledItems = ref([
  {
    id: "radio_button_group_disabled_01",
    text: "First element"
  },{
    id: "radio_button_group_disabled_02",
    text: "Second element"
  }
]);

const radioButtonGroupDisabledOneModel = ref("radio_button_group_disabled_one_01");
const radioButtonGroupDisabledOneItems = ref([
  {
    id: "radio_button_group_disabled_one_01",
    text: "First element"
  },{
    id: "radio_button_group_disabled_one_02",
    text: "Second element"
  },{
    id: "radio_button_group_disabled_one_03",
    text: "Third element"
  }
]);

</script>
