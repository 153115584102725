<template>
  <div>
    <In-Head :title="$t('customers.special_cards.show.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.special_cards.show.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
        <sm-button
          :href="$page.props.routes.create"
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="$page.props.table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.special_cards.table.${col}`)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps?.data?.name }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="ml-1 text-bo-red">Törölve</span>
                <span
                  v-if="slotProps.data?.expired"
                  class="ml-1 text-bo-red">Lejárt</span>
              </template>
              <template v-else-if="col === 'qr_code'">
                <span v-if="slotProps.data?.has_qr_code">
                  <i class="fas fa-qrcode" />
                </span>
                <span v-else>-</span>
              </template>
              <template v-else-if="col === 'expiration_date'">
                {{ slotProps.data?.expiration_date ? useDateFormat(slotProps.data?.expiration_date) : '' }}
              </template>
              <template v-else-if="col === 'created_at'">
                {{ slotProps.data?.created_at ? useDateFormat(slotProps.data?.created_at) : '' }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.edit"
                  preset="edit-icon"
                  :href="slotProps.data?.routes.edit" />
                <sm-delete-button
                  v-if="slotProps.data?.permissions.destroy"
                  :route="slotProps.data?.routes.destroy"
                  name="deleteSpecialCard"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

const tableFields = ref([
  "name", "identifier", "expiration_date", "qr_code", "created_by", "created_at"
]);
</script>
