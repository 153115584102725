<template>
  <tbody>
    <inventory-create-row
      v-for="item in $page.props.groupedItems"
      v-show="shouldShow(item)"
      :key="item.id"
      :item="item"
    />
  </tbody>
</template>

<script setup>
import InventoryCreateRow from "./InventoryCreateRow.vue";

const props = defineProps({
  search: { type: String, required: true },
});

const shouldShow = (item) => item.club_product.name.toLowerCase().includes(props.search.toLowerCase());
</script>
