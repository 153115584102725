<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <div class="flex sm-input-group">
      <div
        v-if="$slots.prepend"
        class="sm-input-group-prepend">
        <slot name="prepend" />
      </div>
      <div class="p-0 sm-input-text">
        <label
          class="block sm-input-file-label"
          :class="{'opacity-25 pointer-events-none': disabled}"
          :for="computedId">
          {{ fileName || $t('generic.choose_file') }}
        </label>
        <input
          :id="computedId"
          type="file"
          :name="name"
          class="hidden"
          :class="inputClasses"
          :placeholder="placeholder"
          data-vue
          @input="updateInput($event)">
      </div>
      <div
        v-if="$slots.afterInput">
        <slot name="afterInput" />
      </div>
      <label
        class="cursor-pointer sm-input-group-append"
        :class="{'opacity-75 pointer-events-none': disabled}"
        :for="computedId">
        <p>{{ $t('generic.browse') }}</p>
      </label>
    </div>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import uniqueId from "lodash.uniqueid";

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: [String, Boolean], required: false, default: "" },
  value: { type: String, required: false, default: "" },
  modelValue: { type: [String, Object], required: false, default: "" },
  tooltip: { type: String, required: false, default: "" },
  type: { type: String, required: false, default: "text" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  errors: { type: Array, required: false, default: () => [] },
  inline: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  rows: { type: Number, required: false, default: 5 },
  half: { type: Boolean, required: false, default: false },
  inputClasses: { type: String, required: false, default: "" },
});
provide("props", props);

const emit = defineEmits(["update:modelValue"]);

const fileName = ref("");

const computedId = computed(() => {
  if (props.id) {
    return props.id;
  } else {
    return `id_${uniqueId()}`;
  }
});

function updateInput(e) {
  emit("update:modelValue", e.target.files[0]);
  fileName.value = e.target.files[0].name;
}
</script>
