<template>
  <div
    ref="toggleButtonContainer"
    class="relative">
    <button
      v-ripple
      type="button"
      :disabled="disabled"
      class="p-ripple"
      :class="customClasses"
      @click="onClick">
      <span>{{ title }}</span>
      <vue-feather
        type="chevron-down"
        class="w-3 h-3 ml-0.5" />
    </button>
    <OverlayPanel ref="overlayPanel">
      <ul
        :class="wrapperOptions"
        class="p-1.5 mt-0.5 text-brand-gray-800 text-left list-none bg-white border border-brand-gray-200 rounded-lg relative z-[1000]">
        <slot name="firstOption" />
        <li
          v-for="option in options"
          :key="option.id">
          <template v-if="checkVisibility(option)">
            <Link
              v-if="actionData?.routes[option?.to] || option.to"
              :href="actionData?.routes[option?.to] ? actionData?.routes[option?.to] : option.to"
              class="sm-dropdown-item"
              :class="[{ 'active': option?.to?.includes($page.url) }, option?.class]">
              <vue-feather
                v-if="option.icon"
                :type="option.icon"
                size="14" />
              <span>{{ option.label || option.name }}</span>
            </Link>
            <a
              v-if="option.toLegacyRoute"
              :href="actionData?.routes[option?.toLegacyRoute] ? actionData?.routes[option?.toLegacyRoute] : option.toLegacyRoute"
              class="sm-dropdown-item"
              :class="[{ 'active': option?.toLegacyRoute?.includes($page.url) }, option?.class]">
              <vue-feather
                v-if="option.icon"
                :type="option.icon"
                size="14" />
              <span>{{ option.label || option.name }}</span>
            </a>
            <button
              v-if="option.action"
              type="button"
              class="sm-dropdown-item"
              :class="option?.class"
              @click="$nextTick(() => option.action(actionData))">
              <vue-feather
                v-if="option.icon"
                :type="option.icon"
                size="14" />
              <span>{{ option.label || option.name }}</span>
            </button>
          </template>
        </li>
        <slot name="lastOption" />
      </ul>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import { onClickOutside } from "@vueuse/core";
import { usePrimeVue } from "primevue/config";

const $primevue = usePrimeVue();

defineExpose({
  $primevue
});

const props = defineProps({
  disabled: { type: Boolean, default: false },
  title: { type: String, default: "" },
  options: { type: Array, default: () => [] },
  // styling props
  size: { type: String, default: "xs" },
  color: { type: String, default: "" },
  icon: { type: String, default: "" },
  align: { type: String, default: "left" },
  actionData: { type: Object, requuired: false, default: null },
});

const active = ref(false);

const customClasses = computed(() => {
  let size = "";
  let color = "";

  size = `sm-btn-${props.size}`;
  color = props.color !== "" ? `sm-btn-${props.color}` : "";
  return `sm-btn ${size} ${color} p-ripple`;
});

const wrapperOptions = computed(() => {
  return `${props.align}-0`;
});

const overlayPanel = ref();
const onClick = () => {
  overlayPanel.value.toggle(event);
};

const checkVisibility = (option) => {
  if (option?.visible) {
    if (typeof option.visible === "function") {
      return option.visible(props.actionData);
    } else {
      return option.visible;
    }
  }

  return true;
};

const toggleButtonContainer = ref();
onClickOutside(toggleButtonContainer, () => active.value = false);
</script>
