<template>
  <sm-nav-tabs :data="navTabs" />
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
const page = usePage();

const navTabs = ref([
  {
    to: page.props.routes.subpages.default,
    label: window.trans("customers.navs.defaults"),
    active: page.component === "customers/show/index",
    inertia: true
  },
  {
    to: page.props.routes.subpages.transactions,
    label: window.trans("customers.navs.transactions")
  },
  {
    to: page.props.routes.subpages.balanceChanges,
    label: window.trans("customers.navs.balance_changes")
  },
  {
    to: page.props.routes.subpages.checkins,
    label: window.trans("customers.navs.checkins")
  },
  {
    to: page.props.routes.subpages.cardUsages,
    label: window.trans("customers.navs.card_usages")
  },
  {
    to: page.props.routes.subpages.ticketUsages,
    label: window.trans("customers.navs.ticket_usages")
  },
  {
    to: page.props.routes.subpages.lockerAssignments,
    label: window.trans("customers.navs.locker_assignments"),
    enabled: page.props.permissions.canListLockerAssignments,
  },
]);

</script>
