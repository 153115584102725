<template>
  <tbody>
    <template
      v-for="(items, title) in $page.props.groupedItems"
      :key="title">
      <tr>
        <td
          colspan="3"
          class="text-lg font-semibold">
          {{ title }}
        </td>
      </tr>
      <inventory-create-row
        v-for="item in items"
        v-show="shouldShow(item)"
        :key="item.id"
        :item="item"
      />
    </template>
  </tbody>
</template>

<script setup>
import InventoryCreateRow from "./InventoryCreateRow.vue";

const props = defineProps({
  search: { type: String, required: true },
});

const shouldShow = (item) => item.club_product.name.toLowerCase().includes(props.search.toLowerCase());
</script>
