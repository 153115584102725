<template>
  <sm-card header-title="Slots">
    <div class="">
      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot before the picker<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #prepend<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #prepend&gt;Prepend&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-date-picker
            label="Prepend date picker slot">
            <template #prepend>
              Prepend
            </template>
          </sm-date-picker>
          <sm-time-picker
            label="Prepend time picker slot">
            <template #prepend>
              Prepend
            </template>
          </sm-time-picker>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the picker<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #append<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #append&gt;Append&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-date-picker
            label="Append date picker slot">
            <template #append>
              Prepend
            </template>
          </sm-date-picker>
          <sm-time-picker
            label="Append time picker slot">
            <template #append>
              Prepend
            </template>
          </sm-time-picker>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the label<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterLabel<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterLabel&gt;After label&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-date-picker
            label="After label date picker slot">
            <template #afterLabel>
              After label
            </template>
          </sm-date-picker>
          <sm-time-picker
            label="After label time picker slot">
            <template #afterLabel>
              After label
            </template>
          </sm-time-picker>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the picker<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterInput<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterInput&gt;After input&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-date-picker
            label="After input date picker slot">
            <template #afterInput>
              After input
            </template>
          </sm-date-picker>
          <sm-time-picker
            label="After input time picker slot">
            <template #afterInput>
              After input
            </template>
          </sm-time-picker>
        </div>
      </div>
    </div>
  </sm-card>
</template>
