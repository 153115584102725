<template>
  <div>
    <transition name="fade">
      <div
        v-if="modalIsVisible"
        class="fixed top-0 left-0 w-full h-full hidden overflow-hidden outline-0 z-[1050] transition-all opacity-0"
        :class="{'opacity-100 overflow-y-auto !block': modalIsVisible}">
        <button
          type="button"
          :class="{'cursor-not-allowed': !closeable}"
          class="fixed top-0 left-0 w-full h-full bg-black/30"
          @click="toggleModalVisibility" />
        <div
          class="relative w-auto m-2 pointer-events-none my-7 mx-auto transition-transform ease-out duration-300 max-w-[95vw]"
          :class="modalSize">
          <div class="relative flex flex-col w-full bg-white border rounded-lg pointer-events-auto border-brand-gray-200 bg-clip-padding outline-0">
            <header class="relative flex items-start justify-between p-4 border-b border-brand-gray-200 rounded-t-md">
              <h5 class="mb-0 leading-normal">
                {{ modalTitle }}
              </h5>
              <button
                v-if="closeButton"
                type="button"
                class="absolute w-4 h-4 transition-colors -translate-y-1/2 right-4 top-1/2 hover:text-bo-red"
                @click="toggleModalVisibility">
                <vue-feather
                  type="x"
                  class="w-4 h-4" />
              </button>
            </header>
            <div
              class="relative p-4"
              :class="bodyClasses">
              <slot />
            </div>
            <footer
              v-if="$slots.footer"
              class="flex flex-wrap items-center justify-end p-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
              <slot name="footer" />
            </footer>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { onKeyStroke } from "@vueuse/core";
import { nextTick } from "vue";


const props = defineProps({
  modalIsVisible: { type: Boolean, required: true, default: false },
  modalTitle: { type: String, required: false, default: "Sportmate Club" },
  size: { type: String, required: false, default: "" },
  bodyClasses: { type: String, required: false, default: "" },
  closeButton: { type: Boolean, required: false, default: false },
  closeable: { type: Boolean, required: false, default: true },
});

const emit = defineEmits(["toggleModalVisibility"]);

const modalSize = computed(() => {
  let size = "";
  if (props.size === "lg") {
    size = "lg:max-w-3xl";
  } else if (props.size === "xl") {
    size = "lg:max-w-6xl";
  } else {
    size = "lg:max-w-lg";
  }
  return size;
});

const toggleModalVisibility = () => {
  if (props.closeable) {
    emit("toggleModalVisibility");
  }
};

onKeyStroke("Escape", () => {
  if (props.modalIsVisible === true) {
    const activeElement = document.activeElement;
    nextTick(() => {
      if (activeElement.nodeName === "BODY") {
        toggleModalVisibility();
      }
    });
  }
});

</script>

<style lang="postcss">
.fade {
  &-enter-active,
  &-leave-active {
    @apply transition-all;
  }

  &-enter-from,
  &-leave-to {
    @apply opacity-0;
  }

  &-enter-to,
  &-leave-from {
    @apply opacity-100;
  }
}
</style>
