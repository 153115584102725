export default {
  methods: {
    createDiv(staticClass, children = [], options = {}) {
      return this.$createElement("div", {
        staticClass,
        ...options
      }, children);
    },
    createSpan(staticClass, children = [], options = {}) {
      return this.$createElement("span", {
        staticClass,
        ...options
      }, children);
    },
    createLabel(children, options) {
      if (!Array.isArray(children)) {
        children = this.$t(children);
      }

      return this.$createElement("label", {
        staticClass: "col-form-label",
        ...options
      }, children);
    },
    createInput(vModel, type = "text", name = "", event = "input", options = {}) {
      return this.$createElement("input", {
        staticClass: "sm-input-text",
        domProps: {
          value: vModel[0][vModel[1]]
        },
        on: {
          [event]: e => {
            let value = e.target.value;
            if (vModel.length === 3 && vModel[2] === "number") {
              value = parseInt(value);
            }

            this.$set(vModel[0], vModel[1], value);
          },
          keyup: e => {
            if (e.keyCode !== 13) {
              return;
            }

            // e.target.form?.submit();
            e.target.form && e.target.form.submit && e.target.form.submit();
            this.$emit("search-submit", e);
          }
        },
        attrs: {
          type,
          name
        },
        ...options,
      });
    },
    createCheckbox(vModel, id = "", label = null) {
      if (!label) {
        label = this.$t("generic.yes");
      }

      return this.createLabel(
        [
          this.createInput(vModel, "checkbox", id, "change", {
            staticClass: "custom-control-input",
            attrs: {
              id,
              type: "checkbox"
            },
            domProps: {
              checked: vModel[0][vModel[1]]
            },
            on: {
              change: e => this.$set(vModel[0], vModel[1], e.target.checked),
            },
          }),
          this.createSpan("", [
            this.$createElement("svg", {
              staticClass: "inline",
              attrs: {
                stroke: "currentColor",
                "stroke-width": 2,
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                width: 12,
                height: 12,
                fill: "none",
                color: "white",
              },
            }, [
              this.$createElement("use", {
                attrs: {
                  "xlink:href": "#feather-check",
                },
              }),
            ]),
          ]),
          label,
        ],
        {
          staticClass: "sm-toggleable sm-checkbox block mb-0",
          attrs: {
            for: id
          }
        },
      );
    },
    createSelect(vModel, choices = [], name = "", options = {}, usingGroups = false) {
      let choiceList = [];

      if (usingGroups) {
        choiceList = [
          this.$createElement("option", {
            attrs: {
              value: "",
              hidden: true,
              disabled: true,
              selected: true
            }
          }, this.$t("filters.choose")),
        ];

        // In this case, choices has a key => array structure, where the key is
        // the label of the group (if empty, just add options).
        const groups = Object.entries(choices)
          .flatMap(([label, value]) => {
            const innerOptions = Object.entries(value)
              .map(([optionValue, optionLabel]) => {
                return this.$createElement("option", {
                  domProps: {
                    value: optionValue
                  }
                }, optionLabel);
              });

            // This means we are in an optgroup
            if (label !== "") {
              return this.$createElement("optgroup", {
                domProps: { label }
              }, innerOptions);
            }

            return innerOptions;
          });

        choiceList = choiceList.concat(groups);

      } else {
        choiceList = [
          this.$createElement("option", {
            attrs: {
              value: "",
              hidden: true,
              disabled: true,
              selected: true
            }
          }, this.$t("filters.choose")),
          ...choices.map(o => this.$createElement("option", {
            domProps: {
              value: o.value
            }
          }, this.$t(o.label)))
        ];
      }

      return this.$createElement("select", {
        staticClass: "sm-select",
        domProps: {
          value: vModel[0][vModel[1]]
        },
        on: {
          change: e => this.$set(vModel[0], vModel[1], e.target.value)
        },
        attrs: {
          name
        },
        ...options,
      }, choiceList);
    },
  },
};
