<template>
  <div>
    <sm-button
      color="secondary"
      :title="$t('customers.actions.aycm_logging_in')"
      hotkey="F6"
      :loading="form.processing"
      @click="onSubmit" />
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import hotkeys from "hotkeys-js";

const form = useForm({});
const page = usePage();

const onSubmit = () => {
  form.post(page.props.routes.checkInWithAYCM, {
    only: ["clubOpen", "customerCheckedIn", "hasAycmQuickCheckin", "checkinModal", "customer", "errors", "flash"],
  });
};

onMounted(() => {
  hotkeys("f6", "global", (e) => {
    if (page.props.customerCheckedIn || form.processing) {
      return;
    }

    e.preventDefault();
    if (e.repeat) {
      return;
    } else {
      onSubmit();
    }
  });
});

</script>
