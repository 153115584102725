<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <In-Head title="Styleguide (vue)" />

    <sub-header title="Styleguide (vue)" />

    <div class="mb-6 sm-row">
      <div class="sticky top-0 w-full mb-4 sm-col lg:w-3/12 lg:mb-0">
        <div class="sm-list-group sm-list-group-bordered">
          <template
            v-for="item in sideMenu"
            :key="item.icon">
            <Link
              :href="item.url"
              class="sm-list-group-item sm-list-group-item-action"
              :class="{'active': page.url.startsWith(item.url)}"
            >
              <vue-feather
                :type="item.icon"
                class="inline w-4 h-4 mr-2 translate-y-1"
              />
              {{ item.label }}
            </Link>
            <div v-auto-animate>
              <div v-if="item.sub && item.sub.length !== 0 && page.url.startsWith(item.url)">
                <div
                  v-for="sub in item.sub"
                  :key="sub.url">
                  <a
                    :href="sub.url"
                    class="py-1.5 pl-10 text-xs block w-full bg-white border-b transition-colors hover:bg-bo-gray-50 cursor-pointer"
                    :class="{'!text-bo-blue': isHrefActive(sub.url)}">
                    {{ sub.label }}
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="w-full sm-col lg:w-9/12">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3";

const page = usePage();

const sideMenu = ref([
  {
    url: "/inertia/styleguide/form",
    icon: "align-justify",
    label: "Form",
  },
  {
    url: "/inertia/styleguide/buttons",
    icon: "inbox",
    label: "Buttons",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/input-wrapper",
    icon: "plus",
    label: "Input wrapper",
    sub: [
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      }
    ]
  },
  {
    url: "/inertia/styleguide/labels",
    icon: "minus",
    label: "Labels",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      }
    ]
  },
  {
    url: "/inertia/styleguide/inputs",
    icon: "square",
    label: "Inputs",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/selects",
    icon: "menu",
    label: "Selects",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#async-defaults",
        label: "Async Defaults",
      },
      {
        url: "#async-props",
        label: "Async Props",
      },
      {
        url: "#async-slots",
        label: "Async Slots",
      }
    ]
  },
  {
    url: "/inertia/styleguide/pickers",
    icon: "server",
    label: "Pickers",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/checkboxes",
    icon: "check-square",
    label: "Checkboxes",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  },
  {
    url: "/inertia/styleguide/radios",
    icon: "check-circle",
    label: "Radios",
    sub: [
      {
        url: "#defaults",
        label: "Defaults",
      },
      {
        url: "#props",
        label: "Props",
      },
      {
        url: "#slots",
        label: "Slots",
      },
      {
        url: "#emits",
        label: "Emits",
      },
    ]
  }
]);

const isHrefActive = () => {
  return false;
  // return `${router.page.url.split("#")[1]}` === url.split("#")[1];
};

</script>
