<template>
  <div>
    <In-Head :title="$t('backoffice.pages.inventory.create')" />

    <sub-header :title="$t('backoffice.pages.inventory.create_with_warehouse', {warehouse: $page.props.warehouse.name})">
      <template #breadcrumbs>
        <ol class="sm-breadcrumb">
          <li class="sm-breadcrumb-item">
            <span> Értékesítés </span>
          </li>
          <li class="sm-breadcrumb-item active">
            <a
              :href="$page.props.routes.inventoryIndex"
              class="text-bo-blue"
            >
              Standolás
            </a>
          </li>
        </ol>
      </template>
      <template #actions>
        <form
          method="post"
          :action="$page.props.endpoints.downloadPdfWithStock"
        >
          <input
            type="hidden"
            name="_token"
            :value="props.csrfToken"
          >
          <sm-button
            type="submit"
            icon="download"
            title="PDF Készletadatokkal"
          />
        </form>
        <form
          method="post"
          :action="$page.props.endpoints.downloadPdfWithoutStock"
        >
          <input
            type="hidden"
            name="_token"
            :value="props.csrfToken"
          >
          <sm-button
            type="submit"
            icon="download"
            title="PDF Készletadatok nélkül"
          />
        </form>
      </template>
    </sub-header>

    <div class="w-4/12">
      <sm-input
        v-model="search"
        placeholder="Keresés">
        <template #append>
          <vue-feather
            type="search"
            class="w-4 h-4" />
        </template>
      </sm-input>
    </div>

    <sm-card no-padding>
      <table
        class="mb-0 table-fixed sm-table sm-table-responsive sm-table-borderless-top"
      >
        <thead>
          <tr>
            <th>{{ $t("Termék") }}</th>
            <th>{{ $t("Rendszer szerinti mennyiség") }}</th>
            <th style="width: 200px">
              {{ $t("Tényleges mennyiség") }}
            </th>
          </tr>
        </thead>
        <inventory-list
          v-if="$page.props.view === 'alphabetical'"
          :search="search"
        />
        <inventory-grouped-list
          v-if="$page.props.view === 'grouped-by-type'"
          :search="search"
        />
      </table>
      <template #footer>
        <form
          method="get"
          :action="props.endpoints.confirm">
          <input
            type="hidden"
            name="_token"
            :value="props.csrfToken"
          >
          <sm-button
            color="primary"
            title="Standolás"
            type="submit"
          />
        </form>
      </template>
    </sm-card>
  </div>
</template>

<script setup>
import InventoryGroupedList from "./_partials/InventoryGroupedList.vue";
import InventoryList from "./_partials/InventoryList.vue";

const props = defineProps({
  endpoints: { type: Object, required: true },
  csrfToken: { type: String, required: true },
});

const search = ref("");
</script>
